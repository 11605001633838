<template>
  <div class="home">
    <!-- <header-menu v-if="headerState" :logo="logoUrl" /> -->
    <router-view />
    <!-- <foot-menu v-if="footerState" :deepLogoUrl='deepLogoUrl' /> -->
  </div>
</template>
<script>
export default {
  name: "pc-home",
  components: {
  },
  data() {
    return {
      headerState: true,
      footerState: true,
      logoUrl: "",
      deepLogoUrl: "",
    };
  },
  watch: {
    $route: {
      handler(newVal) {
        const { headerIsShow, footerIsShow } = newVal.meta;
        this.headerState = headerIsShow ? false : true;
        this.footerState = footerIsShow ? false : true;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      },
      immediate: true,
    },
  },
  mounted() {
    // this.getWebsiteInfo(); //获取网站配置信息
    // this.urlState = window.location.host.includes("form_data")
    //   ? "false"
    //   : "true";
  },
  methods: {
    getWebsiteInfo() {
      //获取网站配置信息
      let url = "/v1/websiteInfo";
      if (window.location.host == "zzcl.kjcxchina.com") {
        url = "/v1/websiteInfo";
      }
      if (window.location.host == "zzjt.kjcxchina.com") {
        url = "/v1/groupWebsiteInfo";
      }

      this.$axios.post(url, { is_pc: 2 }).then((res) => {
        localStorage.setItem("siteInfo", JSON.stringify(res.data.site_info));
        localStorage.setItem("navigation", JSON.stringify(res.data.navigation));
        document.getElementById("linIcon").href = res.data.site_info.site_icon;
        document.getElementById("titleName").innerHTML =
          res.data.site_info.name;
        // this.logoUrl = res.data.site_info.site_logo || require('@/assets/pc/logo.png');
        // this.deepLogoUrl = res.data.site_info.footer_logo || require('@/assets/pc/footer_logo.png');
      });
    },
  },
};
</script>

<style lang="scss">
.flex {
  display: flex;
  align-items: center;
}
.cursor {
  cursor: pointer;
}
.none {
  display: none;
}
input::-webkit-input-placeholder {
  font-size: 14px;
}
input::-moz-input-placeholder {
  font-size: 14px;
}
input::-ms-input-placeholder {
  font-size: 14px;
}
.overflow_e {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all; // 注意这个文字多行很重要
  -webkit-box-orient: vertical;
}
// 面包屑
.nav_title {
  // width: 1200px;
  margin: 0 auto;
  padding-top: 40px;
  p {
    color: #666666;
    font-size: 18px;
    cursor: pointer;
  }
  p.arrow {
    margin: 0 15px;
  }
  p:last-child {
    color: #000;
    font-weight: 500;
    cursor:auto;
  }
}
</style>
