<template>
  <div class="m-home">
    <!-- <headerMenu /> -->
    <router-view />
    <!-- <Login v-if="mLoginModal.show" />
    <Tip :text="tipModal.text" :ico="tipModal.ico" :showBtns="tipModal.showBtns" v-if="tipModal.show" :fun="tipModal.fun" :confirmFun="tipModal.confirmFun" :confirmBtnText="tipModal.confirmBtnText" :cancelFun="tipModal.cancelFun" :cancelBtnText="tipModal.cancelBtnText" /> -->
  </div>
</template>
<script>
// import Login from "@/views/m/common/login.vue";
// import Tip from "@/views/m/common/tip.vue";
// import headerMenu from "@/components/m/header.vue";

import { mapState, mapMutations } from "vuex";
export default {
  name: "MHome",
  components: {
    // Login,
    // Tip,
    // headerMenu
  },
  data() {
    return {};
  },
  mounted() {
    // this.getWebInfo()
    // let set_info = JSON.parse(sessionStorage.getItem("websiteState"));
    // document.getElementById("linIcon").href = set_info.site_icon;
    // document.getElementById("titleName").innerText= set_info.name;
    
  },
  methods: {
    getWebInfo(){
      let hostLink = window.location.host=='localhost:8081'?'kskweb.kskstudy.com':window.location.host
       let url = "/v1/websiteInfo";
      if (window.location.host == "zzcl.kjcxchina.com") {
        url = "/v1/websiteInfo";
      }
      if (window.location.host == "zzjt.kjcxchina.com") {
        url = "/v1/groupWebsiteInfo";
      }
      this.$axios
        .post(url, { host: hostLink })
        .then((res) => {
          if (res.code == 0) {
            sessionStorage.setItem("websiteState", JSON.stringify(res.data));
            this.$store.state.websiteState = res.data
            document.getElementById("linIcon").href = res.data.site_info.site_icon;
           document.getElementById("titleName").innerHTML = res.data.site_info.name;
          }
        });
    }

  },
  computed: {
    ...mapState(["tipModal", "mLoginModal", "userInfo"]),
  },
};
</script>

<style lang="scss" scoped>
.scroll-box {
  height: 100vh;
  overflow-y: auto;
}
</style>
