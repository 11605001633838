import {
    Message,
    Pagination,
    Upload,
    Button,
    Checkbox,
    CheckboxButton,
    CheckboxGroup,
    Select,
    Option,
    Form,
    FormItem,
    Input,
    
    DatePicker,

} from 'element-ui'
const element = {
    install: function(Vue) {


        Vue.use(Pagination)
        Vue.use(Upload)
        Vue.use(Button)
        Vue.use(Checkbox)
        Vue.use(CheckboxGroup)
        Vue.use(CheckboxButton)
        Vue.use(Select)
        Vue.use(Option)
        Vue.use(Form)
        Vue.use(FormItem)
        Vue.use(Input)
        Vue.use(DatePicker)
        Vue.prototype.$message = Message;
        // Vue.prototype.$confirm = MessageBox.confirm;
        // Vue.prototype.$alert = MessageBox.alert;
        // Vue.prototype.$notify = Notification;

    }
}

export default element