import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import VueLazyload from 'vue-lazyload'
import 'lib-flexible'
// import Qs from 'qs'
import MD5 from 'js-md5';
import Meta from "vue-meta"
import Axios from '@/utils/axios'

import 'element-ui/lib/theme-chalk/index.css'
import element from './element/index'
Vue.use(element)

Vue.use(Meta)
Vue.config.productionTip = false
Vue.prototype.$axios = Axios //引用axios
Vue.prototype.$MD5 = MD5;
// Vue.use(VueLazyload)
new Vue({
  router,
  // Qs,
  store,
  render: h => h(App)
}).$mount('#app')
